<template>
<div class="main home">
    <div class="home-box">
        <h1>水司管理</h1>
        <div class="home-content">
            <search-item :searchInfo.sync="info" @searchList="searchList" @addEnpr="addEnpr"></search-item>
            <!-- 表格 -->
            <div class="table_container">
                <el-table size="mini" border :data="tableData" stripe v-loading="tableLoading" style="width: 100%" align='center'>
                    <el-table-column prop="enprName" label="水司名称" align='center'>
                    </el-table-column>
                    <el-table-column prop="enprNo" label="水司编号" align='center'>
                    </el-table-column>
                    <el-table-column prop="customizeNo" label="用户名" align='center'>
                    </el-table-column>
                    <el-table-column prop="pwd" label="鉴权密码" align='center'>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="handleUpdate(scope.row)" type="text" size="small">修改</el-button>
                            <el-button type="text" size="small" @click="del(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <pagination :paginationInfo="paginationInfo" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>
            </div>
        </div>
    </div>
    <el-dialog :modal-append-to-body='false' title="添加/修改水司" :visible.sync="showDialog" width="22%">
        <el-form ref="form" label-width="80px">
            <el-form-item label="水司名称">
                <el-input style="width:100%;" v-model="enprData.enprName"></el-input>
            </el-form-item>
            <el-form-item label="水司编号">
                <el-input style="width:100%;" v-model="enprData.enprNo"></el-input>
            </el-form-item>
            <el-form-item label="用户名">
                <el-input style="width:100%;" v-model="enprData.loginName"></el-input>
            </el-form-item>
            <el-form-item label="密码">
                <el-input v-model="enprData.loginPwd">
                    <el-button slot="append" type="primary" @click="generatePwd">随机生成</el-button>
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" :loading="submitLoading" @click="onSubmit">提交</el-button>
                <el-button @click="close">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</div>
</template>

<script>
import Pagination from "@/components/pagination";
import SearchItem from "./searchItem";
export default {
    data() {
        return {
            // 表格loading
            tableLoading: true,
            tableData: [], //表格数据
            paginationInfo: {
                pageIndex: 1, // 当前位于哪页
                pageTotal: 0, //表格总页数
                pageSize: 10, //每页显示条数
                pageSizes: [5, 10, 15, 20], //每页显示个数选择器的选项设置
                layout: "total, sizes, prev, pager, next, jumper" // 翻页属性
            },
            pidList: [],
            centerDialogVisible: false,
            registerId: "",
            info: {
                name: ""
            },
            activeName: '0',
            showDialog: false,
            enprData: {
                enprNo: '',
                enprName: '',
                loginName: '',
                loginPwd: '',
                id: null
            },
            editState: false,
            submitLoading: false
        }
    },
    mounted() {
        let that = this
        this.getEnprList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
    },
    methods: {
        //  获取表格信息
        getEnprList(currpage, pageSize, info) {
            console.log(info)
            this.tableLoading = true;
            let params = {
                pageNum: currpage,
                pageSize: pageSize,
                enprName: info ? info.name : ''
            }
            this.tableData = []
            console.log(params)
            this.$api.getEnprList(params).then(res => {
                this.tableLoading = false;
                if (res && res.code === 0) {
                    this.paginationInfo.pageTotal = parseInt(res.data.total)
                    this.paginationInfo.pageIndex = res.data.pageNum
                    this.tableData = res.data.list
                } else {
                    this.tableLoading = false;
                    this.$message.error(res.errorMsg)
                }
            }).catch(error => {
                console.log(error)
                this.tableLoading = false;
            })
        },
        searchList(e) {
            console.log(e)
            this.info = e
            this.getEnprList(1, this.paginationInfo.pageSize, e)
        },
        // 上下分页
        handleCurrentChange(val) {
            // console.log('上下分页',val)
            let that = this
            this.paginationInfo.pageIndex = val
            this.getEnprList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
        },
        // 每页显示多少条
        handleSizeChange(val) {
            // console.log('每页显示多少条',val)
            let that = this
            this.paginationInfo.pageSize = val
            this.getEnprList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
        },
        submitDialog(e) {
            this.registerId = e.registerId
            this.centerDialogVisible = true
        },
        onSubmit() {
            let that = this
            if (this.enprData.enprName.toString().trim() == "") {
                this.$message.warning('请输入水司名称')
                return
            }
            if (this.enprData.enprNo.toString().trim() == "") {
                this.$message.warning('请输入水司编号')
                return
            }
            if (this.enprData.loginName.toString().trim() == "") {
                this.$message.warning('请输入用户名')
                return
            }
            if (this.enprData.loginPwd.toString().trim() == "") {
                this.$message.warning('请输入鉴权密码')
                return
            }
            let params = {
                enprName: this.enprData.enprName,
                enprNo: this.enprData.enprNo,
                customizeNo: this.enprData.loginName,
                password: this.enprData.loginPwd
            }
            this.submitLoading = true
            if (this.editState) {
                params.id = this.enprData.id
                this.$api.updateEnprInfo(params).then(res => {
                    console.log(res)
                    this.submitLoading = false
                    if (res.code === 0) {
                        this.$message({
                            message: '更新成功',
                            type: 'success'
                        });
                       this.showDialog = false
                        this.enprData = {
                            enprNo: '',
                            enprName: '',
                            loginName: '',
                            loginPwd: '',
                            id: null
                        }
                        this.editState = false
                        this.getEnprList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            } else {
                this.$api.addEnpr(params).then(res => {
                    this.submitLoading = false
                    console.log(res)
                    if (res.code === 0) {
                        this.$message({
                            message: '添加成功',
                            type: 'success'
                        });
                        this.showDialog = false
                        this.enprData = {
                            enprNo: '',
                            enprName: '',
                            loginName: '',
                            loginPwd: '',
                            id: null
                        }
                        this.editState = false
                        this.getEnprList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }
        },
        handleUpdate(e) {
            this.showDialog = true
            console.log(e)
            this.enprData = {
                enprNo: e.enprNo,
                enprName: e.enprName,
                loginName: e.customizeNo,
                loginPwd: e.pwd,
                id: e.id
            }
            this.editState = true
        },
        addEnpr() {
            this.enprData = {
                enprNo: '',
                enprName: '',
                loginName: '',
                loginPwd: '',
                id: null
            }
            this.showDialog = true
        },
        close() {
            this.showDialog = false
        },
        del(e) {
            let that = this
            this.$confirm('确定要删除该条热搜关键词吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.delRS({
                    id: e.id
                }).then(res => {
                    if (res.status == "OK") {
                        this.getEnprList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
                        this.$message({
                            type: 'success',
                            message: '删除成功'
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: '删除失败'
                        });
                    }
                })
            }).catch(() => {});
        },
        generatePwd() {
            this.enprData.loginPwd = this.$Fn.RandomPassword(8)
        }
    },
    components: {
        Pagination,
        SearchItem
    },
    filters: {
        icCardFilters(val) {
            if (val) {
                if (val.length > 10) {
                    return val.substring(0, 6) + "****" + val.substring(val.length - 4)
                } else {
                    return val
                }
            } else {
                return val
            }
        }
    }
}
</script>

<style>
/* 标题 */
.menu .title1 img {
    top: 22px;
}

.menu .title1 h3 {
    font-size: 20px;
    margin-top: -14px;
}

/* 分页 */
.el-pagination button,
.el-pagination span:not([class*=suffix]) {
    padding: 0 10px;
}

.home .block-title {
    width: 100%;
    padding: 0 0 28px 20px;
    display: flex;
    align-items: center;
}

.home .block-title h3 {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #2d3039;
}

.home .a-text {
    text-decoration: underline;
    color: #1e61ce;
}

.home .block-title .btn {
    width: 180px;
    height: 35px;
    background-color: #1e61ce;
    border-radius: 20px;
    margin-left: 20px;
    font-weight: normal;
    font-stretch: normal;
    color: #ffffff;
    padding: 0;
}

.home .btn span {
    font-size: 16px !important;
    color: #fff !important;
}

.home .block-title span {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #63717A;
}

.home .top {
    width: 100%;
    padding: 0 0 10px 0;
    border-radius: 10px;
    background: #fefefe;
}

.home .top .title {
    width: 100%;
    height: 90px;
    line-height: 90px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    font-family: PingFangSC-Semibold;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #2d3039;
    border-bottom: 1px solid #f2f3f5;
}

.home .el-col {
    border-radius: 10px;
}

.home .grid-content {
    border-radius: 10px;
    /* min-height: 36px; */
}

.home .leftInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.home .leftInfo .headPic {
    width: 165px;
    height: 165px;
    border-radius: 100%;
    border: 8px solid #fff;
    position: relative;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    box-shadow: 0px 12px 30px 0px rgba(6, 37, 117, 0.12);
}

.home .leftInfo .headPic img {
    width: 100%;

}

.home .rightInfo {
    margin-top: 10px;
    padding-right: 10px;
}

.home .rightInfo .name-text {

    margin-bottom: 20px;
    font-family: PingFangSC-Semibold;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #2d3039;
}

.home .rightInfo .info-text img {
    width: 26px;
    height: 26px;
    margin-right: 20px;
}

.home .rightInfo .info-text {
    margin-bottom: 20px;
    font-family: PingFangSC-Medium;
    font-size: 18px;
    letter-spacing: 0px;
    color: #63717a;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    text-align: left
}

.home .rightInfo .info-text h1 {
    font-size: 20px;
    font-weight: 600;
    font-family: PingFangSC-Medium;
    color: #2d3039;
    margin-left: 10px;
    display: block;
    font-size: 90.5%;
}

.home .middle {
    width: 100%;
    border-radius: 10px;
}

.home .middle .grid-content {
    background: #fff
}

.home .title1 {
    width: 100%;
    display: flex;
    padding: 0 20px 20px 20px;
    align-items: center;
    margin-top: -10px;
    border-bottom: 1px solid #f2f3f5;
}

.home .title1 h3 {
    font-size: 20px;
    margin-top: -10px;
}

.home .title1 img {
    position: relative;
    top: 18px;
    margin-right: 10px;
}

.home .ajs-Title {
    margin-left: 20px;
}

.home .ajs {
    margin-left: 20px;
    color: #0033cf;
}

.home .left-block {
    height: 200px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    /* border-right: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8; */
}

.home .right-block {
    height: 200px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid #d8d8d8;
}

.home .main-block {
    height: 200px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid #d8d8d8;
}

/*案件办理天数 标签统计 hmm 090403*/
.home .labelModule {
    background: #F2F5FA;
    border: 1px solid #D6E0F0;
    border-radius: 10px;
    display: inline-block;
    width: 100%;
    padding: 20px;
}

.home .labelModule:first-child {
    margin-bottom: 20px;
}

.home .labelModule ul li {
    width: 100%;
    font-size: 16px;
    color: #63717A;
    text-align: center
}

.home .labelModule ul li:first-child {
    margin-bottom: 10px;
}

.home .labelModule ul li font {
    font-size: 41px;
    color: #2D3039;
}

/*公共部分 hmm*/
.p20 {
    padding: 20px;
}

.pb30 {
    padding-bottom: 30px;
}

/*办案类型 数据超出 显示样式 hmm 090404*/

.widthBig .labelModule {
    width: 49.2%;
}

.widthBig .labelModule:first-child {
    margin-bottom: 30px;
    margin-right: 20px;
}

.widthSmall {
    float: right;
}

.rs-block {
    width: 98%;
    height: 80px;
    text-align: right;
    line-height: 80px;
}
</style>
